import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  query popularContent(
    $limit: Int!
    $offset: Int!
  ) {
    popularContent(
      limit: $limit
      offset: $offset
    ) {
      ...ContentFields
    }
  }
  ${ContentFields}
`;
